













































import { defineComponent } from '@vue/composition-api'
import custombutton from '../../../components/custom/CButtonBasic.vue'
import CustomBreadcrumbs from '../../../components/custom/CBreadcrumbs.vue'
import LatestNews from '../../../almarwan/views/blog/components/LatestNews.vue'
import DealersLogos from "../../../pages/Dealers/components/DealersLogos.vue"
import SeoInfo from '../../../modules/catalog/product/components/SeoInfo.vue'
import type { MetaInfo } from 'vue-meta'
import { useUiHelpers } from '~/composables'

export default defineComponent({
  components: {
    custombutton,
    LatestNews,
    OurLocation: () => import('../../../almarwan/components/OurLocation.vue'),
    NavBanner: () => import('../../../components/custom/CNavBanner.vue'),
    NavSection: () => import('../../../components/custom/CNavSection.vue'),
    NavBlackTile: () => import('../../../components/custom/CNavBlackTile.vue'),
    CustomAccordion: () => import('../../../components/custom/CAccordion.vue'),
    CustomHeading: () => import('../../../components/custom/CDashedTitle.vue'),
    TextSection: () => import('../../../components/custom/CTextSection.vue'),
    TwoColTextSection: () => import('../../../components/custom/C2ColTextSection.vue'),
    AccordionHeading: () => import('../../../components/custom/CAccordionHeading.vue'),
    CustomBreadcrumbs,
    DealersLogos,
    SeoInfo
  },
  data() {
    return {
      breadcrumbs : [
        { text: this.$i18n.t('Services'), link: this.localePath('/services')},
        { text: this.$i18n.t('Parts & attachments'), link: this.localePath('/services/parts-attachments-sales') }
      ],
      //desc1: this.$i18n.t("As an authorized distributor of OEM machinery attachments, Al Marwan carries an extensive inventory of heavy machinery attachments from ABI, Jisung (JSC), Toku, World Attachments, and Simex. We supply the construction, mining, and quarrying industries with crushing and screening buckets, hydraulic rock breakers (jackhammers), vibratory heads, auger drives, and more."),
      desc1: this.$i18n.locale === 'ar' ? 
      "تمتلك شركة المروان مخزون واسع من ملحقات الآلات الثقيلة تابعة لعدة علامات تجارية مثل أي بي آي، وجايسونغ (جي اس سي)، وتوكو، و سيفا، وسيمكس بصفتها الموزع الحصري المعتمد لتلك العلامات، كما تؤمن المرفقات اللازمة في صناعات البناء والتعدين والمحاجر مثل <a href='/ar/c/attachment-buy/buckets/crusher-buckets'>دلاء التكسير ودلاء الغربلة</a> <a href='/ar/c/attachment-buy/breakers/large-breakers'>وكسارات الصخور</a> <a href='/ar/c/attachment-buy/breakers/large-breakers'>الهيدروليكية</a> (دقاقات الصخور)، والمطارق الاهتزازية، و<a href='/ar/c/attachment-buy/drilling-attachment/auger-drives'>محركات البريمات</a> والمزيد من المرفقات الملائمة لاحتياجاتك."
      : "As an authorized distributor of OEM machinery attachments, Al Marwan carries an extensive inventory of heavy machinery attachments from ABI, Jisung (JSC), Toku, and Simex. We supply the construction, mining, and quarrying industries with crushing and screening buckets, hydraulic rock breakers (jackhammers), vibratory heads, auger drives, and more.",
      desc2: this.$i18n.t("As an authorized dealer of ABI vibratory hammers, Toku, Jisung, Simex and other attachments in the UAE and GCC, Al Marwan provides top-tier machinery attachments from top brands for a variety of applications as a trusted heavy machinery and attachments distributor."),
      desc3: this.$i18n.locale === 'ar' ? 
             "نفتخر بشراكتنا الحصرية مع شركة أي بي آي لتوزيع <a href='/ar/c/attachment-buy?types=839&q=1718202877981'>المطارق الاهتزازية</a> و<a href='/ar/c/attachment-buy?types=927&brand=644&q=1718202915769'>معدات دق الأساس </a>الضرورية لبناء أساسات بناء قوية ومتينة في جميع أنحاء الإمارات العربية المتحدة والمملكة العربية السعودية، إضافةً إلى مجموعة ملحقات سيمكس المستخدمة بالعديد من التطبيقات، كما نقدم <a href='/ar/c/attachment-buy?brand=684&q=1718202960210'>مجموعة كسارات الصخور الهيدروليكية</a> المقدمة من جايسونغ والمتوفرة بتكوينات وأحجام متعددة لتتلائم مع جميع أشكال الحفارات."
              : "As the exclusive dealers of <a href='/c/attachment-buy?brand=644' >ABI vibratory hammers</a> and <a href='/c/attachment-buy?sort=POSITION&catalog=840' >pile driving attachments</a>, we ensure efficient placement of piling and shoring equipment across the UAE and Saudi Arabia. Our partnership with Simex allows us to provide top-tier heavy equipment attachments for a variety of applications. Additionally, we offer a range of <a href='/c/attachment-buy?brand=684' >Jisung hydraulic rock breakers</a>, perfectly fitted for excavators and available in multiple configurations and sizes.",
      desc3_1: this.$i18n.locale === 'ar' ? 
                "تتميز مجموعة <a href='/ar/c/attachment-buy?brand=683&q=1718202989162'>دقاقات الصخور المتينة من توكو </a>بموديلاتها التسعة والمتوفرة بشكلين للتركيب؛ أعلى المعدة وجانبًا والمتلائمة مع أشكال متعددة من المعدات الثقيلة. صُممت دقاقات الصخور تلك إضافةً إلى كسارات الصخور من جايسونغ (جي إس سي) لتؤدي عملها في البيئات الصعبة كمواقع العمل البحرية وتطبيقات الهدم تحت الماء مع ضمان السلامة والكفاءة في جميع المهام."
                : "The robust <a href='/c/attachment-buy?brand=683' >Toku jackhammer</a> range, available in top and side types, features nine models to suit a broad carrier range. These jackhammers, a.k.a. rock breakers, are engineered to excel in challenging environments, including marine worksites and underwater applications.",
      desc4: this.$i18n.t("We supply OEM Toku, Jisung, and Simex machinery attachments as authorized distributors for the UAE market. We also carry regularly-in-stock heavy equipment spare parts including mechanical, electric, and hydraulic machinery components."),
      desc5: this.$i18n.t("Al Marwan Machinery offers convenient heavy equipment financing for its machinery and attachments as an authorized dealer, including different flexible financing options for its ABI vibratory hammers, Toku, and Jisung Korean jackhammers and hydraulic rock breakers and more, ensuring that you can acquire the equipment you need without hassle. With our machinery financing solutions, you can enhance your operational efficiency and performance without compromising your budget."),
      desc6: this.$i18n.t("Al Marwan Heavy Machinery provides original, used, and rebuilt heavy equipment spare parts, readily available to keep your operations running smoothly. Our store is consistently restocked to ensure maximum uptime by providing the necessary parts on demand."),
      
      accordionData : [
        {
          summary: this.$i18n.t('Full spare parts coverage'),
          details: this.$i18n.t('Regular replacement of worn-out heavy equipment spare parts is essential for prolonging equipment life, reducing downtime, and enhancing efficiency. Our expansive inventory includes consumables, mechanical, drivetrain, and electrical components.'),
          // icon: 'home',
        },
        {
          summary: this.$i18n.t('Readily available OEM inventory'),
          details: this.$i18n.t("We empower local foundation works and civil engineering projects in the UAE by offering genuine ABI vibratory hammers, in addition to Toku and Jisung (JSC) hydraulic rock breakers as attachments for excavators and backhoes for demolition and quarrying."),
        },
        {
          summary: this.$i18n.t("Customize machinery with attachments"),
          details: this.$i18n.t("Providing customized machinery to meet the diverse demands of any construction project, from vibratory hammers fitted on drilling rigs to jackhammers attached to excavators and backhoes for breaking applications.")
        },
      ],
      accordionDataCol2 : [
        {
          summary: this.$i18n.t('Unlock versatile machinery performance'),
          details: this.$i18n.t("Using OEM machinery attachments such as hydraulic breakers, and specialized buckets to adapt your equipment for various tasks, eliminates the need to invest in multiple machines, allowing you to handle diverse projects with precision and ease."),
        },
        {
          summary: this.$i18n.t('Quality parts at Al Marwan store'),
          details: this.$i18n.t("Al Mawrwan parts store features components from leading brands, ensuring durability and optimal performance for your equipment, including parts for engines, hydraulics, electrical systems, or undercarriages."),
          // icon: 'home',
        },
        {
          summary: this.$i18n.t("Machinery attachments authorized distributor in the GCC"),
          details: this.$i18n.t("Representing top-tier heavy machinery attachments from renowned brands such as ABI, Toku, Jisung, Kobelco, Simex, and CIFA, among others available at the UAE, Saudi Arabia, and Oman markets."),
        }
      ],
      infoData: `<div>
        <h2>Machinery attachments sale</h2>
        <p>
          As an <a href="/dealers">authorized distributor</a> of renowned brands such as <a href="/dealers/dealership-simex-699">Simex</a>, <a href="/dealers/dealership-toku-683">Toku</a>, and <a href="/dealers/dealership-jisung-684">Jisung</a>, Al Marwan machinery offers a comprehensive range of parts and attachments to meet the diverse needs of our clients in construction, mining, landscaping, and demolition sectors.
        </p>

        <h2>Specialty attachments</h2>
        <p>For more specialized applications, we offer a variety of attachments designed to extend the functionality of your machinery beyond its basic capabilities:</p>
        <ul>
            <li><span>Quick couplers and adapters:</span> Facilitate rapid switching between different attachments, significantly boosting productivity by reducing downtime.</li>
            <li><span>Augers:</span> Ideal for drilling deep foundation holes, these attachments transform your mini excavator into a powerful drilling rig.</li>
            <li><span>Forestry and landscaping tools:</span> Equip your machinery with mulchers, brush cutters, tree shears, and rakes for efficient land clearing and landscaping tasks.</li>
            <li><span>Material handling attachments:</span> Pallet forks, grapples, and rotary grabs for effective </li>
            <li><span>Breaking, cutting, and ripping tools:</span> Equip your machinery with hammers, rippers, and trenchers for heavy-duty demolition, ripping, and trenching operations.</li>
        </ul>

        <h2>Advanced equipment solutions</h2>
        <p>
          We pride ourselves on offering advanced attachments that significantly enhance the versatility and efficiency of your heavy equipment:
        </p>
        <ol>
            <li><span> Rock breakers:</span> Our hydraulic rock breakers from Toku and Jisung are engineered for robust performance in breaking through rock and concrete, essential for construction, mining, and demolition projects. Toku's lineup features 15 hydraulic rock breakers, including models like the TNB400LU2, the largest in Toku's range, and the TNB151LU2, suitable for demolition and quarry work. Jisung offers the JSB series, known for innovations like the inward and outward valve systems and the ARES series for extreme conditions, with components made from high-quality materials like SNCM26V carbon steel and Hardox 400 steel.</li>
        </ol>

        <h2>Quality machinery parts</h2>
        <p>
          At Al Marwan machinery, we prioritize quality and reliability. All our attachments and parts are sourced from globally recognized brands known for their durability and superior performance. Whether you need OEM parts, aftermarket solutions, or rebuilt components, we ensure that every product meets the highest standards of quality and compatibility with your machinery.
        </p>

        <h2>Expanded parts offering</h2>
        <p>
          We carry parts for drilling equipment, breakers, buckets, piling attachments, and specialty attachments. Our inventory includes components for small, medium, and large breakers, vibro hammers, crusher buckets, screening buckets, auger drives, cutter heads, and road planers.
        </p>
        <p>
          As authorized dealers for brands like <a href='https://www.abi-eqp.com/' target='_blank'>ABI</a>, Toku, <a href='http://www.koreahammer.com/' target='_blank'>Jisung</a>, and Simex, we ensure that you have access to high-quality, genuine parts. Furthermore, we supply consumable parts and offer maintenance services as part of our warranty and service contracts, ensuring your machinery remains in top working condition. Our skilled technicians are equipped to perform all necessary maintenance and service tasks, providing you with peace of mind and maximizing the lifespan and performance of your equipment.
        </p>

        <h2>Comprehensive support</h2>
        <p>
          Our service extends beyond just providing parts and attachments. We offer expert advice, maintenance tips, and comprehensive support to ensure that your equipment operates at peak efficiency. Our team is dedicated to helping you select the right attachments for your specific needs, ensuring that you achieve the best possible results in your projects.
        </p>
      </div>`,
      infoData_ar: `<div>
        <h2>معلمومات إضافية</h2>
        <p>
          كموزع معتمد للعلامات التجارية الشهيرة مثل توكو (Toku) وجايسنغ جي إس سي (Jisung JSC) وإي بي آي (ABI) وسيمكس(Simex)، تقدم شركة المروان للمعدات الثقيلة مجموعة شاملة من قطع الغيار وملحقات المكنات لتلبية احتياجات عملائنا المتنوعة في قطاعات البناء والتعدين والهدم وغيرها.
        </p>

        <h2>الملحقات المتخصصة</h2>
        <p>للتطبيقات الأكثر تخصصًا، نقدم مجموعة متنوعة من الملحقات المصممة لتوسيع قدرات معداتك الثقيلة بما يتجاوز وظائفها الأساسية:</p>
        <ul>
            <li><span>الوصلات السريعة والمحولات:</span> تسهل التبديل السريع بين الملحقات المختلفة، مما يعزز الإنتاجية بشكل كبير عن طريق تقليل فترة توقف المكنة عن العمل.</li>
            <li>ا<span>لوصلات السريعة والمحولات:</span> تسهل التبديل السريع بين الملحقات المختلفة، مما يعزز الإنتاجية بشكل كبير عن طريق تقليل فترة توقف المكنة عن العمل.</li>
            <li><span>:أدوات الغابات وتنسيق الحدائق</span>: لتزويد معداتك الثقيلة بمُلحقات التقطيع، ومقصات الأعشاب والأشجار والأمشاط لتنفيذ مهام تنظيف الأراضي وتنسيق الحدائق بكفاءة.</li>
            <li><span>ملحقات مناولة المواد:</span> نقدم شوكات البالت، والملاقط، والقبضات الدوارة لمناولة المواد بفعالية في ساحات الخردة، ومراكز إعادة التدوير، والمحاجر.</li>
            <li><span>أدوات التكسير والقطع والتجريف:</span> لتزويد معداتك الثقيلة بالمطارق، والمجارف، وقواطع الخنادق للقيام بعمليات الهدم الثقيلة، والتجريف، وحفر الخنادق.</li>
          </ul>

        <h2>ملحقات المعدات الحديثة</h2>
        <p>
          نفخر بتقديم ملحقات متقدمة تعزز بشكل كبير من تنوع وكفاءة معداتك الثقيلة:
        </p>
        <ol>
            <li>مطارق الصخور: نُوفر <a href="/ar/c/attachment-buy/piling-attachments/vibro-hammers">مطارق هيدروليكية</a> من العلامات التجارية (Toku - Jisung) مصممة للأداء القوي في تكسير الصخور والخرسانة. هذه المطارق ضرورية لمشاريع البناء والتعدين والهدم. تشمل المجموعة 15 مطرقة هيدروليكية من العلامة التجارية Toku، بما في ذلك l, مثل TNB400LU2، الأكبر في مجموعة Toku، وTNB151LU2، المناسبة لأعمال الهدم والمحاجر. تقدم Jisung سلسلة JSB المعروفة بالابتكارات الحديثة مثل أنظمة الصمامات الداخلية والخارجية وسلسلة ARES للظروف القاسية، مع مكونات مصنوعة من مواد عالية الجودة مثل فولاذ SNCM26V والكربون وفولاذ HARDOX 400.</li>
        </ol>

        <h2>قطع غيار مكنات عالية الجودة</h2>
        <p>
          ولي في شركة المروان للمعدات الثقيلة الأولوية للجودة والموثوقية. جميع ملحقاتنا وقطع الغيار لدينا مستمدة من علامات تجارية معترف بها عالميًا معروفة بمتانتها وأدائها الممتاز. سواء كنت تحتاج إلى قطع غيار أصلية، خدمات ما بعد البيع، أو مكونات معاد بناؤها، نضمن أن كل قطعة تُلبي أعلى معايير الجودة والتوافق مع معداتك.
        </p>

        <h2>قطع غيار أصلية على مدار الساعة</h2>
        <p>
          نوفر قطع غيار لمعدات الحفر، المطارق، الدلاء، ملحقات التكديس، والملحقات المتخصصة. يشمل مخزوننا مكونات للكسارات الصغيرة والمتوسطة والكبيرة، مطارق الاهتزاز، دلاء التكسير، دلاء الغربال، محركات المثاقب، رؤوس القطع، وماكينات تخطيط الطرق.
        </p>
        <p>
          كموزعين معتمدين للعلامات التجارية مثل (ABI, Toku, Jisung, Simex) نضمن لك الوصول إلى قطع غيار أصلية عالية الجودة. بالإضافة إلى ذلك، نوفر قطع الغيار الاستهلاكية ونقدم خدمات الصيانة كجزء من عقود الضمان والخدمة، مما يضمن بقاء معداتك في حالة عمل ممتازة. لدينا مجموعة من الفنيين القادرين على أداء جميع مهام الصيانة الضرورية، مما يوفر لك راحة البال ويزيد من عمر وأداء معداتك الثقيلة.
        </p>

        <h2>الدعم الشامل</h2>
        <p>
          تتجاوز خدماتنا توفير قطع الغيار والملحقات، بل نُقدم نصائح للصيانة، والدعم الشامل لضمان أن تعمل معداتك الثقيلة بكفاءة عالية. فريقنا مُكرّس لمساعدتك في اختيار الملحقات الأنسب لاحتياجاتك الخاصة، مما يضمن الوصول إلى أفضل النتائج الممكنة في مشاريعك.
        </p>
      </div>`
    }
  },
  
  setup() {
    const { getFullUrl } = useUiHelpers()
    return {
      getFullUrl
    }
  },
  head(): MetaInfo {
    const title = this.$i18n.t('partsTitle').toString()
    const description = this.$i18n.t('partsDesc').toString()
    const url = this.getFullUrl(this.localePath('/services/parts-attachments-sales'));
    const meta = [];
    const script = [];
    const link = [];
    meta.push({ 
      property: 'title', 
      name: 'title', 
      content: title 
    })
    meta.push({
      hid: 'og:title',
      name: 'og:title',
      content: title
    });
    meta.push({
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title
    });
    meta.push({
      hid: 'twitter:text:title',
      name: 'twitter:text:title',
      content: title
    });

    meta.push({
      hid: 'url',
      name: 'url',
      content: url,
    });
    meta.push({
      hid: 'og:url',
      name: 'og:url',
      content: url,
    });

    meta.push({
      hid: 'description',
      name: 'description',
      content: description
    });
    meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: description
    });
    meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description
    });
    meta.push({ 
      hid: 'apple-mobile-web-app-title', 
      name: 'apple-mobile-web-app-title', 
      content: title 
    });
    meta.push({ 
      hid: 'og:apple-mobile-web-app-title', 
      name: 'og:apple-mobile-web-app-title', 
      content: title 
    });
    meta.push({ 
      hid: 'og:site_name', 
      name: 'og:site_name', 
      content: title 
    });
    meta.push({ 
      hid: 'site_name', 
      name: 'site_name', 
      content: title 
    });
    meta.push({ 
      property: 'og:image',
      content: `${process.env.BASE_URL}favicon.ico`
      },
      { 
        property: 'twitter:image', 
        content: `${process.env.BASE_URL}favicon.ico`
      });
    script.push({
      type: 'application/ld+json',
      class: 'saswp-schema-markup-output',
      json: [
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": title,
        "url": url,
        "description": this.$i18n.t('serviceDesc').toString(),
        "publisher": {
          "@type": "Organization",
          "name": title,
          "logo": {
            "@type": "ImageObject",
            "url": `${process.env.BASE_URL}_nuxt/icons/icon_512x512.3b9037.png`,
            "width": 630,
            "height": 630
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": url,
        }
      }]
    });
    link.push({
      rel: 'canonical',
      href: url,
    });
    return {
      title,
      meta,
      script,
      link
    };
  },
})
